import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  sidebarMenuList,
  getPlayBooksContentPages,
  updatesidebarMenuList,
  getEmbedToken,
  getMsalBasedPlaybookContentPages,
  updatePresistReport,
  updateActiveMenuList,
  updatePlaybooksContentPages,
  updateActivePage
} from '../../redux/actions/index';
import MenuList from './menulist';
import utils from '../../utils/index'
import { useStyles } from "./indexStyles.js";

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  // Redux Response //
  const sideBarMenu = useSelector((state) => state.sideBarMenu);
  const playBooksContent = useSelector((state) => state.playbookContent);
  const playbookContentPages = useSelector((state) => state.playbookContentPages);
  const reportContent = useSelector((state) => state.getReportContent);
  const dynamicReports = useSelector((state) => state.dynamicReports);
  const bookmarkView = useSelector((state) => state.bookmarkView);
  const presistApplicable = useSelector((state) => state.updatePresist);
  const activeSideBarMenu = useSelector((state) => state.activeSideBarMenu);
  const [activeMenuCall, setActiveCall] = useState(false);


  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubMenu, setActiveSubMenuName] = useState("");
  const [activeSubMenuID, setActiveSubMenuID] = useState("");
  const [isGroupedReportPage, setReportPage] = useState(false);
  const [PBIReports, setReportsData] = useState([]);

  const [open, setOpen] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const { activeMsalToken, activeRegion, activePage, activeBusinessUnit } = useSelector((state) => state.activeDynamicData);

  const { state } = history.location;
  const { description } = sideBarMenu;

  /**
 * useEffect hook for calling sidebar MenuList API.
 *
 * @example
 * @state
 * const state = application history object state
 * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
 * const sidebarMenuList = Redux Action dispatcher for sidebarMenuList API Call in case of empty data
 * return (
 *    Sidebar MenuList DATA
 * )
 */
  useEffect(() => {
    dispatch(updatePresistReport({ presist: true }));
    if (activeRegion) {
      let params = {
        region: activeRegion
      }
      // setOpen(activePage.sidebar)
      dispatch(sidebarMenuList(params));
    }
  }, [dispatch, activeRegion]);

  const getActiveMenu = (value) => {
    console.log('activeSubMenuID', value)
    setActiveIndex(value.activeIndex);
    setActiveCall(true);
    setReportPage(value.isGroupedReportPage);
    if (!!state) {
      state.type = "presistReport";
    }
  };

  /**
   * useCallback hook for updating self service, foundation analysis and resources data to the user.
   *
   * @example
   * @state
   * const activePage =  self service, foundation analysis and resources
   * const data = description
   * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
   * const viewName = description
   * const viewIndex = 0
   * return (
   *    self service, foundation analysis and resources side bar data
   * )
   */
  const updatebusinessUnitSidebar = useCallback((reportData) => {
    const pathName = window.location.href;
    let urlParams;
    if (pathName.length > 0 && pathName.includes('?')) {
      urlParams = pathName.split('?')[1];
      console.log('SideBarContent-reportData urlParams', urlParams, urlParams.split('&&'))
    }
    let urlIndex = urlParams && Number(urlParams.split('&&')[0].split('=')[1]);
    let parentModule = urlParams && urlParams.split('&&')[1].split('=')[1].replace(/%20/g, " ");
    let childModule = urlParams && urlParams.split('&&')[2].split('=')[1].replace(/%20/g, " ");
    let activeReportSecId = urlParams && urlParams.split('&&')[4].split('=')[1].replace(/%20/g, " ");
    let activeReportkey = urlParams && urlParams.split('&&')[5].split('=')[1].replace(/%20/g, " ");
    let activeReportPage = urlParams && urlParams.split('&&')[8].split('=')[1].replace(/%20/g, " ");
    let parentId = urlParams && urlParams.split('&&')[9].split('=')[1].replace(/%20/g, " ");
    let childId = urlParams && urlParams.split('&&')[10].split('=')[1].replace(/%20/g, " ");
    let data = activeBusinessUnit.filter((el) => el.viewStatus == 'Active');
    data.sort((function (a, b) { return a.parentOrder - b.parentOrder }));
    let activeParentModule = data.filter((item) => item.parentId == parentId)[0];
    let activeChildModule;
    if (activeParentModule) {
      activeChildModule = activeParentModule && activeParentModule.childModule.find((el) => el.childId == childId);
    }
    else {
      let activeParentModule = data.filter((item) => item.parentModule == parentModule)[0];
      activeChildModule = activeParentModule && activeParentModule.childModule.find((el) => el.title == childModule);
    }
    console.log('SideBarContent-reportData activePage', parentId, childId, activeBusinessUnit, data, activeParentModule, activeChildModule);
    if (activeChildModule && Object.keys(activeChildModule).length > 0 && !activeMenuCall) {
      console.log('SideBarContent-reportData activePage', activeChildModule);
      sessionStorage.setItem('ActivePage', JSON.stringify(activeChildModule));
      dispatch(updateActivePage(activeChildModule))
    }
    console.log('SideBarContent-reportData', reportData, description, parentModule, childModule, urlIndex, childModule, activeReportkey, activeReportSecId, data, activeParentModule, parentId, activeChildModule, childId);
    if (Array.isArray(reportData) && reportData.length > 0) {
      description.sort((a, b) => { return a.order - b.order });
      let data = utils.uniqueArrayData(reportData.concat(description), 'viewName');
      let activeReportIndex = data.findIndex((el) => el.viewName === parentModule || el.viewName === childModule);
      if (activeReportIndex > -1 && !activeMenuCall) {
        setActiveIndex(activeReportIndex);
        setActiveSubMenuName(childModule);
        setReportPage(activeReportPage);
        setActiveSubMenuID(activeReportPage ? activeReportSecId !== '#1306C1' ?  activeReportSecId : '' : '');
      }
      setReportsData(data);
      console.log('SideBarContent-reportData', 111, activeReportIndex, activeReportSecId);
    }
    else if (!reportData) {
      description.sort((a, b) => { return a.order - b.order });
      let activeReportIndex = description.findIndex((el) => el.viewName === parentModule || el.viewName === childModule);
      if (activeReportIndex > -1 && !activeMenuCall) {
        setActiveIndex(activeReportIndex);
        setReportPage(activeReportPage);
        setActiveSubMenuName(childModule);
      }
      else {
        const activePath = window.location.pathname;
        if (activePath === '/resources/links-trainings') {
          let activeReportIndex = description.findIndex((el) => el.viewName === "Links & Trainings");
          if (activeReportIndex > -1) {
            setActiveIndex(activeReportIndex);
          }
        }
        else if (activePath === '/analytics/self-service') {
          let activeReportIndex = description.findIndex((el) => el.viewName === "Self-Service");
          if (activeReportIndex > -1) {
            setActiveIndex(activeReportIndex);
          }
        }
      }
      setReportsData(description);
      console.log('SideBarContent-reportData', 222, activeReportIndex, description);
    }
    if (state && state.type === "bookmark") {
      console.log('bookmarkState', state);
      setActiveIndex(state.viewIndex);
      setActiveSubMenuName(state.activeReport)
    } else if (state && state.type === "ShareReport") {
      console.log('bookmarkState', state);
      setActiveIndex(state.viewIndex);
      setActiveSubMenuName(state.activeReport)
    }
    // else if ( urlIndex){
    //   console.log('OpenNewtabState', urlIndex, title, activeRprtId);
    //   setActiveIndex(urlIndex);
    //   setActiveSubMenuName(title);
    //   setActiveSubMenuID(activeRprtId);
    // }
  }, [activeBusinessUnit, activeMenuCall, description, dispatch, state]);


  /**
  * useEffect hook for updating business unit data to the user.
  *
  * @example
  * @state
  * const activePage = business unit activePage
  * const data = description
  * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
  * const viewName = description
  * const viewIndex = 0
  * return (
  *    business unit activePage
  * )
  */
  useEffect(() => {
    if (activePage) {
      let reportData;
      const { multipleMenuList, sidebar, viewBuilderReport, key } = activePage;
      console.log('SideBarContent-presistApplicable', activePage, multipleMenuList, sidebar, viewBuilderReport, key, reportContent.data, playBooksContent.data);
      if (!sidebar) { }
      if (utils.isValidArray(playBooksContent.data)) {
        playBooksContent.data.sort((a, b) => { return a.order - b.order });
        reportData = playBooksContent.data;
        console.log('SideBarContent-presistApplicable', 111, activePage, playBooksContent.data,);
      }
      if (utils.isValidArray(reportContent.data)) {
        reportContent.data.sort((a, b) => { return a.order - b.order })
        console.log('SideBarContent-presistApplicable', 222, reportContent.data);
        let selections = {
          reportId: reportContent.data[0]['reportId'],
          groupId: reportContent.data[0]['groupId'],
          viewID: reportContent.data[0].viewId,
          pageType: activePage.key,
        }
        let res = {
          data: reportContent.data
        }
        let payload = { ...res, selections };
        dispatch(updatePlaybooksContentPages(payload));
      }
      else if (utils.isValidArray(dynamicReports.data)) {
        console.log('SideBarContent-presistApplicable', 333, dynamicReports.data);
        let selections = {
          reportId: reportContent.data[0]['reportId'],
          groupId: reportContent.data[0]['groupId'],
          viewID: reportContent.data[0].viewId,
          pageType: activePage.key,
        }
        let res = {
          data: reportContent.data
        }
        let payload = { ...res, selections };
        dispatch(updatePlaybooksContentPages(payload));
      }
      else {
        setMenuList([{ viewName: activePage.pageName }]);
      }
      updatebusinessUnitSidebar(reportData)
    }
  }, [dispatch, activeIndex, activeRegion, activePage, bookmarkView.bookmarkState, bookmarkView.bookmarkID, playBooksContent.data, reportContent.data, updatebusinessUnitSidebar, dynamicReports.data,]);


  /**
  * funcation callback from child component.
  *
  * @example
  * @state
  * const value = funcation arguments
  * )
  */
  const getActiveSubMenu = (value) => {
    setActiveSubMenuName(value.activeSubMenuName);
    if (value.isGroupedReportPage) {
      setActiveSubMenuID('')
    }
    setReportPage(value.isGroupedReportPage);
  };

  /**
  * useEffect hook for updating Playbooks/ Dartboards Reports Token and pages.
  *
  * @example
  * @state
  * const PBIReports = Playbooks/ Dartboards/ Customized Report
  * const activeIndex = active menu index in nested array element of reports
  * const grouped =  grouped basis report based on region
  * const customizableFlag = customized report based on pages
  * const msalTokenEnabled = MSAL Token Enabled Report based on region
  * return (
  *    useEffect hook for updating Playbooks / Dartboards Reports Token and pages.
  * )
  */
  useEffect(() => {
    if (presistApplicable.presist && utils.isValidArray(PBIReports)) {
      let activeReport = PBIReports[activeIndex];
      if (activeReport && activeReport.region === activeRegion) {
        let { msalEnabled, grouped, reportPages, hiddenPages, htmlTemp, subReport, customizableFlag } = activeReport;
        console.log('SelectedActiveReport APICall', activeReport, activeIndex, activePage,)
        if (!msalEnabled && !grouped) {
          let params = utils.checkValidPBIds(htmlTemp, customizableFlag);
          console.log('SelectedActiveReport params', params)
          if (params && params.groupId && params.reportId) {
            dispatch(getEmbedToken(params));
          }
        }
        if (grouped && subReport.length === 0) {
          // let reportIds = [];
          let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
          // PBIReports.forEach((el) => { if (el.grouped) { reportIds.push(el.viewId) } })
          const params = { ...reportId, viewID: activeReport.viewId, pageType: activePage && Object.keys(activePage).length > 0 ? activePage.key : activeReport.buType };
          dispatch(getMsalBasedPlaybookContentPages(params));
        }
        if (!grouped && reportPages && !hiddenPages && subReport.length === 0) {
          let params = utils.checkValidPBIds(htmlTemp, customizableFlag);
          dispatch(getPlayBooksContentPages(params));
        }
      }
    }
  }, [dispatch, PBIReports, activePage, presistApplicable, activeRegion, activeIndex]);


  /**
  * useEffect hook for updating Playbooks/ Dartboards Reports pages and token w.r.t. active menu.
  *
  * @example
  * @state
  * const PBIReports = Playbooks/ Dartboards/ Customized Report
  * const activeIndex = Active menu index in nested array element of PBIReports
  * const playbookContentPages = Active Menu's subReport/ Report Section/ Report Pages
  * const customizableFlag = customized report based on pages
  * const msalTokenEnabled = MSAL Token Enabled Report based on region
  * const htmlTemp = Object of groupId, reportId, MSAL TOKEN
  * const subReport = Active Menu's subReport Array where Report Pages will be pushed.
  * const openMenu =  Active Menu's Open/ Close Status
  * const activeSubMenuName/ID = Active Menu's subReport Array where default 0th index value will be active.
  * return (
  *    useEffect hook for updating Playbooks / Dartboards Reports Token and pages.
  * )
  */
  useEffect(() => {
    if (utils.isValidArray(PBIReports)) {
      dispatch(updatesidebarMenuList([]));
      let activeReport = PBIReports[activeIndex] ? PBIReports[activeIndex] : PBIReports[0];
      console.log('updateActiveReportIndex', activeReport, activeIndex);
      if (activeReport) {
        let { viewName, customizableFlag, htmlTemp, msalEnabled, grouped, reportPages } = activeReport;
        console.log('updateActiveReportData', activeReport, viewName, customizableFlag, htmlTemp, msalEnabled, grouped, reportPages, playbookContentPages, activeSubMenu, activeSideBarMenu);
        const { data } = playbookContentPages;
        activeReport.isReportActive = true;
        if (data && Array.isArray(data) && data.length > 0 && htmlTemp && htmlTemp['reportId'] === playbookContentPages['reportId']) {
          data.forEach((el) => el.activeReportPage = true);
          data.sort(function (a, b) { return a.order - b.order });
          if (htmlTemp && htmlTemp['reportId'] === playbookContentPages['reportId']) {
            let landingPageIndex = data.findIndex((el) => el.displayName.startsWith('Landing'));
            if (landingPageIndex > -1) { data.splice(landingPageIndex, 1) }
          }
          console.log('updateActiveReportData - subReports', PBIReports, activeReport, data, viewName, htmlTemp, playbookContentPages, activeSubMenu, activeSideBarMenu);
        }
        PBIReports.map((report) => {
          if (report.hasOwnProperty('embedReports')) {
            if (!report.embedReports) {
              report.openMenu = false;
              report.multipleMenuList = false;
            }
            else if (report.embedReports) {
              report.openMenu = true;
              report.multipleMenuList = true;
              report.subReport = [];
            }
          }
          if (viewName === report.viewName) {
            report.openMenu = true;
            report.isReportActive = true;
            report.htmlTemp = activeReport.htmlTemp;
          }
          else if (viewName !== report.viewName) {
            report.openMenu = false;
            report.isReportActive = false;
          }
          if (report.grouped) {
            if (report.viewName == viewName) {
              console.log('updateActiveReportMenuData', 111, PBIReports, report.viewName, viewName);
              report.subReport = data.filter(x => x.Name == report.viewName);
            }
            else if (report.viewName !== viewName) {
              report.subReport = [];
            }
          }
          else if (!report.grouped) {
            console.log('updateActiveReportMenuData', 222, PBIReports, report.viewName, viewName);
            if (report.viewName == viewName) {
              report.subReport = data.filter(x => x.buType == report.buType);
            }
            else if (report.viewName !== viewName) {
              report.subReport = [];
            }
          }
          return PBIReports;
        });
        console.log('updateActiveReportMenuData', 333, PBIReports, viewName, data);
        // Updating active report for embed/ display
        if (!grouped) {
          if (!msalEnabled) {
            let updateReport = { ...activeReport }
            updateReport = {}
            if (Object.keys(updateReport).length === 0 && Array.isArray(data) && data.length > 0) {
              if (Object.keys(activeSideBarMenu).length > 0) {
                let index = activeSideBarMenu['activeSubMenuIndex'];
                updateReport = data[index];
                console.log('updateActiveReportData', 222, activeReport, index, activeSubMenu, data, activeSideBarMenu)
              }
              else {
                updateReport = data[0];
              }
            }
            else if (Object.keys(updateReport).length === 0 && Array.isArray(data) && data.length === 0) {
              updateReport = { ...activeReport }
            }
            activeReport = updateReport;
            dispatch(updateActiveMenuList(activeReport));
            console.log('updateActiveReportData', 333, activeReport, updateReport)
          }
          else if (msalEnabled) {
            if (!customizableFlag) {
              activeReport['htmlTemp']['msalToken'] = activeMsalToken;
              dispatch(updateActiveMenuList(activeReport));
              console.log('updateActiveReportData', 444, activeReport,)
            }
            else if (customizableFlag) {
              data.sort(function (a, b) { return a.order - b.order });
              const playBookPages = data;
              activeReport['subReport'] = playBookPages;
              dispatch(updateActiveMenuList(activeReport));
              console.log('updateActiveReportData', 555, activeReport,)
            }
          }
        }
        else if (grouped) {
          if (Object.keys(activeSideBarMenu).length > 0 && activeSideBarMenu.viewName === viewName) {
            let updateReport = { ...activeReport }
            updateReport.activeReportPage = activeSideBarMenu.isReportPage
            if (msalEnabled) {
              updateReport['htmlTemp'] = { ...activeSideBarMenu.htmlTemp, msalToken: activeMsalToken }
            }
            else if (!msalEnabled) {
              updateReport['htmlTemp'] = { ...activeSideBarMenu.htmlTemp }
            }
            activeReport = updateReport;
            console.log('updateActiveReportData', 666, activeReport, activeSubMenu, activeSideBarMenu)
            dispatch(updateActiveMenuList(updateReport));
          }
          else if (Object.keys(activeSideBarMenu).length === 0) {
            let updateReport = { ...activeReport }
            if (activeReport['subReport'].length > 0) {
              let index;
              let activeSubReportIndex;
              let uniqueSubReport = activeReport['subReport'];
              console.log('updateActiveReportData', 'isGroupedReportPage', activeReport, isGroupedReportPage, activeSubMenuID, activeSubMenuID && isGroupedReportPage, activeSubMenu, uniqueSubReport)
              if (isGroupedReportPage &&  activeSubMenuID !== '' ) {
                activeSubReportIndex = uniqueSubReport.findIndex(el => el.viewDescription == activeSubMenuID);
                console.log('updateActiveReportData', 'isGroupedReportPage', isGroupedReportPage, activeSubMenuID, uniqueSubReport, activeSubMenu, activeSubReportIndex)
              }
              else if (activeSubMenu) {
                activeSubReportIndex = uniqueSubReport.findIndex(el => el.displayName == activeSubMenu);
                console.log('updateActiveReportData', 'activeSubMenu', isGroupedReportPage, activeSubMenu, uniqueSubReport, activeSubReportIndex)
              }
              if (activeSubReportIndex === -1 && activeSubMenu) {
                let additionalReportIndex = uniqueSubReport.findIndex(el => el.displayName.toLowerCase().startsWith(activeSubMenu.toLowerCase()))
                console.log('updateActiveReportData', 'activeSubReportIndex', activeSubReportIndex, uniqueSubReport, activeReport, activeSubMenu, activeSubMenu.toLowerCase())
                if (additionalReportIndex > -1) {
                  console.log('updateActiveReportData', 'additionalReportIndex', activeSubReportIndex, uniqueSubReport, activeReport, additionalReportIndex)
                  index = additionalReportIndex
                }
                else {
                  console.log('updateActiveReportData', 'additionalReportIndex', index)
                  index = 0
                }
              }
              else if (activeSubReportIndex > -1) {
                console.log('updateActiveReportData', 'activeSubReportIndex', index, uniqueSubReport,)
                index = activeSubReportIndex
              }
              else { index = 0 }
              console.log('updateActiveReportData', 'index', index)
              let htmlTemp = uniqueSubReport[index].htmlTemp;
              let isReportPage = uniqueSubReport[index].reportPage;

              console.log('updateActiveReportData', 888, activeSubMenuID, activeReport, activeSubReportIndex, index, activeSubMenu, uniqueSubReport[index], activeSideBarMenu, isReportPage)
              updateReport.activeReportPage = isReportPage ? true : false;
              if (!isReportPage) {
                if (msalEnabled) {
                  console.log('updateActiveReportData 1111', 999, activeReport, updateReport, updateReport['htmlTemp'], htmlTemp)
                  updateReport['htmlTemp'] = { ...htmlTemp, msalToken: activeMsalToken }
                }
                else if (!msalEnabled) {
                  console.log('updateActiveReportData 2222', 999, activeReport, updateReport, uniqueSubReport[index], htmlTemp)
                  updateReport['htmlTemp'] = { ...htmlTemp, }
                }
              }
              activeReport = updateReport
              dispatch(updateActiveMenuList(updateReport));
            }
            console.log('updateActiveReportData', 999, activeReport, updateReport)
          }
          else if (msalEnabled) {
            activeReport['htmlTemp']['msalToken'] = activeMsalToken;
            dispatch(updateActiveMenuList(activeReport));
            console.log('updateActiveReportData msalEnabled', activeReport,)
          }
          else {
            dispatch(updateActiveMenuList(activeReport));
            console.log('updateActiveReportData msalEnabled', activeReport,)
          }
        }
        console.log('updateActiveReportData', activeReport, activeIndex, PBIReports, activePage,);
        dispatch(updatesidebarMenuList(PBIReports));
      }
    }
    else {
      dispatch(updateActiveMenuList({}));
      dispatch(updatesidebarMenuList([]));
    }
  }, [dispatch, activeIndex, activeSubMenu, activeRegion, playbookContentPages, PBIReports, bookmarkView.bookmarkState, bookmarkView.bookmarkID, activeMsalToken, activeSideBarMenu, activePage, activeSubMenuID, isGroupedReportPage]);



  /**
  * Function for Opening/Closing the left side bar menu
  *
  * @example
  *
  */
  const handleDrawerToggle = () => {
    setOpen(!open); //  
  };

  const handleDrawerToggleOff = () => {
    setOpen(false);
  };


  console.log('UpdatedSidebarData-ActiveMenuData',
    activePage, activeSubMenu, activeIndex, activeSubMenuID, playbookContentPages,
    // 'SubMenu*********',
    // '>>>', activeRegion, activeMenu,  menuList  '^^^',
    //  activeSubMenuIndex, state
    // '????', PBIReports,  
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleDrawerToggleOff}>
        <Drawer
          drawerHidden
          className={open ? classes.drawer : classes.drawerHidden}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{ paper: classes.drawerPaper }}
          onMouseLeave={handleDrawerToggleOff}
        >
          <MenuList
            menuList={menuList}
            subRegion={props.subRegion}
            getActiveMenu={getActiveMenu}
            getActiveSubMenu={getActiveSubMenu}
            activeSubMenu={activeSubMenu}
            activeSubMenuID={activeSubMenuID}
            drawerToggleOff={open}
          />
        </Drawer>
      </ClickAwayListener>
      <div className={open ? classes.toggleLeft : classes.toggleRight}>
        <IconButton
          edge="end"
          style={{ outline: "none" }}
          onMouseOver={handleDrawerToggle}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <main className={clsx(classes.content, { [classes.contentShift]: open })}>
        <props.component
          subRegion={props.subRegion}
          activeIndex={activeIndex}
          open={open}
        />
      </main>
    </div>
  );
}


